import { Axios, setAuthToken } from "./config/Axios";

export const userLogin = async (payload) => {
  const response = await Axios.post("/auth/login", payload);
  return response;
};

export const getUsersList = async (payload) => {
  setAuthToken();
  const response = await Axios.post("/admin-panel", payload);
  return response;
};

export const getUserFilterSearch = async (payload) => {
  setAuthToken();
  const response = await Axios.post("/admin-panel/user-search", payload);
  return response;
};

export const updateUserStatus = async (payload) => {
  setAuthToken();
  const response = await Axios.post("/admin-panel/update-status", payload);
  return response;
};

export const updateDollarRate = async (payload) => {
  setAuthToken();
  const response = await Axios.post("/admin/update-dollar-rate", payload);
  return response;
};

export const updateNotificationStatus = async (payload) => {
  setAuthToken();
  const response = await Axios.post("/admin/update-notification", payload);
  return response;
};

export const getNotifCount = async () => {
  setAuthToken();
  const response = await Axios.get("/admin/get-notifications-count");
  return response;
};

export const getAllNotifications = async (payload) => {
  setAuthToken();
  const response = await Axios.post("/admin/notifications", payload);
  return response;
};

export const getOfferManagementS1 = async (payload) => {
  setAuthToken();
  const response = await Axios.post("/admin/offer-management-s1", payload);
  return response;
};

export const offerMgmtS1Action = async (payload) => {
  setAuthToken();
  const response = await Axios.post("/admin/add-action", payload);
  return response;
};

export const offerMgmtS1Search = async (payload) => {
  setAuthToken();
  const response = await Axios.post("/admin/product-offer-search", payload);
  return response;
};

export const offerMgmtEdit = async (payload) => {
  setAuthToken();
  const response = await Axios.post("/admin/edit-product-offers", payload);
  return response;
};

export const getOfferManagementS2 = async (payload) => {
  setAuthToken();
  const response = await Axios.post("/admin/offer-management-s2", payload);
  return response;
};

export const getProductCatalogs = async (payload) => {
  setAuthToken();
  const response = await Axios.post("/admin/product-catalogue", payload);
  return response;
};

export const getProductCatalogsSearch = async (payload) => {
  setAuthToken();
  const response = await Axios.post("/admin/product-catalog-search", payload);
  return response;
};

export const getProductCatalogsEdit = async (payload) => {
  setAuthToken();
  const response = await Axios.post("/admin/edit-product-catalogue", payload);
  return response;
};

export const getDashboardReports = async (payload) => {
  setAuthToken();
  const response = await Axios.post("/admin/reports", payload);
  return response;
};

export const getDashboardReportsEnquiries = async (payload) => {
  setAuthToken();
  const response = await Axios.post("/admin/reports-inquiries", payload);
  return response;
};

export const getDashboardReportsSearch = async (payload) => {
  setAuthToken();
  const response = await Axios.post("/admin/reports-search", payload);
  return response;
};

export const getNotifs = async (payload) => {
  setAuthToken();
  const response = await Axios.post("/admin/notifications", payload);
  return response;
};

export const getNotifsSearch = async (payload) => {
  setAuthToken();
  const response = await Axios.post("/admin/notifications-search", payload);
  return response;
};

export const notifsUpdate = async (payload) => {
  setAuthToken();
  const response = await Axios.post("/admin/update-notification", payload);
  return response;
};

export const getSupplierInfo = async (payload) => {
  setAuthToken();
  const response = await Axios.post("/admin/supplier-information", payload);
  return response;
};

export const addSupplierInfo = async (payload) => {
  setAuthToken();
  const response = await Axios.post("/admin/add-supplier", payload);
  return response;
};

export const searchSupplierInfo = async (payload) => {
  setAuthToken();
  const response = await Axios.post("/admin/supplier-search", payload);
  return response;
};

// GET REQUESTS
export const getUserInfo = async () => {
  setAuthToken();
  const response = await Axios.get("/admin/user-info");
  return response;
};

// Download Excel
export const excelDownload = async (payload) => {
  setAuthToken();
  const response = await Axios.post(
    "/admin/excel-download",
    payload, // Data to send (payload)
    {
      // Configuration object
      headers: {
        "Content-Type": "application/json",
      },
      responseType: "blob",
    }
  );
  return response;
};
