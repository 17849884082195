import { useEffect, useState } from "react";
import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import styles from "../../_css/SupplierModal.module.css";
import { notifyError } from "../helper/Toaster";
import _ from "lodash";

const FIELD_NAMES = {
  name: "Name",
  contact: "Contact",
  email: "Email",
  supplierCode: "Supplier's Code",
  companyName: "Company Name",
  location: "Location",
  onboarded: "Onboarded",
  onboardedDate: "Onboarded Date",
};

function SupplierModal(props) {
  const [show, setShow] = useState(props?.showModal);
  const [formData, setFormData] = useState({
    name: "",
    contact: "",
    email: "",
    supplierCode: "",
    companyName: "",
    location: "",
    onboarded: "Yes",
    onboardedDate: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "contact" && value) {
      const countDigits = String(value).length;
      if (!(Number(value) >= 0) || countDigits > 10) {
        return;
      }
    }
    if(name === "supplierCode") {
      if(!(Number(value) >= 0) || value.toString().length > 10) {
        return;
      }
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateValue = (column, value) => {
    let res = true;
    if (column === "email") {
      // Regular expression to validate email addresses
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      res = emailRegex.test(value);
    } else if (column === "contact") {
      // Regular expression to validate phone numbers with exactly 10 digits
      const phoneRegex = /^\d{10}$/;
      res = phoneRegex.test(value);
    } else if (column === "name") {
      // Regular expression to validate names with at least 3 alphabetic characters and no leading or trailing spaces
      const nameRegex = /^(?! )[A-Za-z ]{3,}(?<! )$/;
      res = nameRegex.test(value);
    }
    return res;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let message = "";

    for (let index in formData) {
      if (index !== "onboardedDate") {
        console.log(index, ": ", formData[index]);
        if (!formData[index] || _.isEmpty(formData[index])) {
          message = `${FIELD_NAMES[index]} cannot be empty.`;
          break;
        } else if (!validateValue(index, formData[index])) {
          message = `${FIELD_NAMES[index]} value is not valid.`;
          break;
        }
      } else if (
        index === "onboardedDate" &&
        formData["onboarded"] === "Yes" &&
        _.isEmpty(formData[index])
      ) {
        message = "Onboarded Date is required";
        break;
      }
    }

    if (message) {
      notifyError(message);
      return;
    }

    props.handleContinue(formData);
  };

  useEffect(() => {
    setShow(props?.showModal);
  }, [props]);

  return (
    <>
      <Modal
        className={`width-60 ${styles["width-60"]}`}
        show={show}
        onHide={props.handleShowModal}
      >
        <Modal.Header className={styles["modal-header"]} closeButton>
          <Modal.Title className="fs-20">{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={`d-flex justify-content-center flex-column`}>
          <Form className={styles.formContainer}>
            <Row>
              <Col>
                <Form.Group controlId="formName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    value={formData.name}
                    name="name"
                    type="text"
                    placeholder="Enter name"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formContact">
                  <Form.Label>
                    Contact <i style={{ fontSize: "11px" }}>(10 digits)</i>
                  </Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    type="number"
                    name="contact"
                    value={formData.contact}
                    placeholder="Enter contact"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="formEmail">
                  <Form.Label>Email I'd</Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    type="email"
                    name="email"
                    value={formData.email}
                    placeholder="Enter email"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formSupplierCode">
                  <Form.Label>Supplier's code</Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    type="number"
                    name="supplierCode"
                    value={formData.supplierCode}
                    placeholder="Enter supplier code"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="formCompanyName">
                  <Form.Label>Company Name</Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    type="text"
                    name="companyName"
                    value={formData.companyName}
                    placeholder="Enter company name"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formLocation">
                  <Form.Label>Location</Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    type="text"
                    name="location"
                    value={formData.location}
                    placeholder="Enter location"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="formOnboarded">
                  <Form.Label>Onboarded</Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    as="select"
                    name="onboarded"
                    value={formData.onboarded}
                  >
                    <option>Yes</option>
                    <option>No</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formOnboardedDate">
                  <Form.Label>Onboarded Date</Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    type="date"
                    name="onboardedDate"
                    disabled={formData.onboarded === "Yes" ? false : true}
                    value={formData.onboardedDate}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="secondary" onClick={props.handleShowModal}>
            Cancel
          </Button>
          <Button size="sm" variant="primary" onClick={handleSubmit}>
            Add Supplier
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SupplierModal;
